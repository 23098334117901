<template>
  <ContainerCard :locationMsg="locationMsg">
    <div class="DemandDetails">
      <div class="demandContent">
        <div class="demandItem">
          <div class="demandTitle">
            <div class="titleLeft">
              <p class="title-name">{{ demandDetail.name }}</p>
              <p>
                <span class="makeTime-label">发布时间</span>
                <span class="makeTime-value">{{ demandDetail.make_time }}</span>
              </p>
            </div>
            <div class="button" @click="toIssueDemand()">
              <img class="img" src="../../assets/images/发布需求.png" alt="" />
              <span>发布需求</span>
            </div>
            <!-- <el-button class="button" type="primary" @click="toIssueDemand()">发布需求</el-button> -->
          </div>
          <div class="damandInfo">
            <div class="damandInfo-item">
              <span class="info-label">技术领域</span>
              <span class="info-value">
                <span
                  class="infoButton"
                  plain
                  v-for="(item, index) in demandDetail.techList"
                  :key="index"
                  >{{ item }}</span
                >
              </span>
            </div>
            <div class="damandInfo-item">
              <span class="info-label">单位名称</span>
              <span class="info-value">{{ demandDetail.company }}</span>
            </div>
            <div class="damandInfo-item">
              <span class="info-label">单位地址</span>
              <span class="info-value">{{ demandDetail.address }}</span>
            </div>
          </div>
          <!-- <div class="demandDescript">
            <p class="descriptTitle">需求描述</p>
            <p class="descriptContent">{{ demandDetail.memo }}</p>
          </div> -->
          <div class="info">
            <div class="info-title">
              <img src="../../assets/images/信息资讯.png" alt="" />
              <span class="label">需求描述</span>
            </div>
            <div
              v-if="demandDetail.memo"
              class="info-value"
              v-html="demandDetail.memo"
            ></div>
            <div v-else class="info-img">
              <img src="../../assets/images/暂无内容.png" alt="" />
            </div>
          </div>
        </div>

        <div class="newDemands">
          <div class="newDemands-title">
            <img src="../../assets/images/信息资讯.png" alt="" />
            <span class="title-size">最新需求</span>
          </div>
          <div
            class="newDemand"
            v-for="(item, index) in hotDemandList"
            :key="index"
            @click="toDemandDeatails(item.billid)"
          >
            <!-- <div class="newDemandImg">
              <img :src="item.photo" alt="" />
            </div> -->
            <div class="newDemandInfo">
              <div class="newDemandInfo-item">
                <span class="name">{{ item.name }}</span>
              </div>
              <div class="newDemandInfo-item" style="margin-bottom: 6px">
                <span class="label">发布人</span>
                <span class="value">{{ item.company }}</span>
              </div>
              <div class="newDemandInfo-item">
                <span class="label" style="margin-right:24px">时间</span>
                <span class="value" >{{ item.billdate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ContainerCard>
</template>
<script>
import Location from "../../components/Location.vue";
import ContainerCard from "../../components/newContainerCard.vue";
import {
  getBizdemandById,
  getHotBizdemands,
  getNewestBizdemands,
} from "../../api/demand/demand.js";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  inject: ["reload"],
  data() {
    return {
      locationMsg: [
        { name: "找合作", router: "demand" },
        { name: "需求详情", router: "demand/demandDetails" },
      ],
      demandDetail: {
        name: "",
      },
      hotDemandList: [],
    };
  },
  created() {
    let id = this.$route.query.id;
    this.getData(id);
    this.getHotData();
  },
  methods: {
    reloadFun() {
      this.reload();
    },
    async getData(id) {
      let that = this;
      let res = await getBizdemandById({ id: id });
      let tmp = res.data;
      tmp.techList = [];
      Object.keys(that.techfieldList).forEach(function (key) {
        if (tmp.tech_field?.split(",").indexOf(that.techfieldList[key]) > -1) {
          tmp.techList.push(key);
        }
      });
      this.demandDetail = tmp;
    },
    toDemandDeatails(id) {
      this.$router.replace({
        path: "/demand/demandDetails",
        query: {
          id: id,
        },
      });
      this.reloadFun();
    },
    async getHotData() {
      // let res = await getHotBizdemands({size:5})
      let res = await getNewestBizdemands({ size: 5 });
      this.hotDemandList = res.data;
    },
    toIssueDemand() {
      this.$router.push("/demand/issueDemand");
    },
  },
  components: { Location, ContainerCard },
};
</script>

<style scoped lang="scss">
@mixin textStyle {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #101010;
}
::v-deep .locationList {
  position: absolute;
  left: -27px;
}
.DemandDetails {
  width: 1075px;
  // height: 880px;
  min-height: 550px;
  // padding-bottom: 30px;
}
.demandContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 40px;
}
.demandItem {
  width: 756px;
  box-sizing: border-box;
  // border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 0px 30px 0px 0px;
  .button {
    cursor: pointer;
    width: 112px;
    height: 34px;
    background: #2885ff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      width: 16px;
      height: 14px;
      margin-right: 12px;
    }
    span {
      font-size: 14px;
      line-height: 21px;
      // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-family: 思源黑体 CN Medium;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.demandTitle {
  height: 108px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #ccc;
  // margin-bottom: 20px;
}
.titleLeft > h2 {
  margin: 10px 0;
  font-size: 28px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #101010;
}
.titleLeft > p {
  font-size: 14px;
  color: #aaa;
  margin: 10px 0;
}

.titleLeft {
  @mixin makeTimeFont {
    font-size: 12px;
    line-height: 18px;
    font-family: 思源黑体 CN Regular;
  }
  .title-name {
    font-size: 24px;
    font-family: 思源黑体 CN Regular;
    line-height: 36px;
    margin-bottom: 24px;
    color: #101010;
    font-weight: 600;
  }
  .makeTime-label {
    margin-right: 12px;
    color: #8896a7;
    @include makeTimeFont;
  }
  .makeTime-value {
    margin-right: 12px;
    color: #404040;
    @include makeTimeFont;
  }
}
@mixin damandInfoFont {
  font-size: 12px;
  line-height: 18px;
  font-family: 思源黑体 CN Regular;
}
.damandInfo {
  font-size: 14px;
  // margin-bottom: 40px;
  // height: 104px;
  padding: 20px 0px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  .damandInfo-item {
    margin-bottom: 24px;
    .info-label {
      @include damandInfoFont;
      color: #8896a7;
      margin-right: 12px;
    }

    .info-value {
      @include damandInfoFont;
      color: #404040;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .infoButton {
    padding: 3px 5px;
    border-radius: 3px;
    background-color: #2885ff;
    color: #ffffff;
    font-size: 12px;
    font-family: 思源黑体 CN Regular;
    // @include damandInfoFont;
    margin-right: 12px;
  }
}

.damandInfo .el-button {
  // margin: 10px 10px 10px 0;
}
.info-title {
  margin-bottom: 24px;
  img {
    width: 8px;
    height: 10px;
    margin-right: 12px;
  }
  .label {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #101010;
    line-height: 24px;
  }
  .descriptContent {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #404040;
    line-height: 36px;
    margin-top: 24px;
  }
}
.newDemands {
  position: absolute;
  top: 19px;
  right: 5px;
}
.descriptTitle {
  color: #02a7f0;
  font-size: 28px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #101010;
}
.descriptContent {
  font-size: 14px;
  color: #333;
  line-height: 30px;
}
.newDemands {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 300px;
  box-sizing: border-box;
  // border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 20px 0px;
  .newDemands-title {
    padding-left: 0px;
    margin-top: -3px;
    margin-bottom: 20px;
    img {
      margin-right: 12px;
    }
    .title-size{
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
    }
  }
}
.newDemand {
  display: flex;
  cursor: pointer;
  width: 300px;
  box-sizing: border-box;
  height: 98px;
  padding: 20px 0px 0px 0px;
  border-bottom: 1px solid #f1f1f1;
}
.newDemandInfo {
  // width: 70%;
  @mixin newDemandInfo-label-Font {
    font-size: 12px;
    color: #8896a7;
    line-height: 18px;
    font-family: Regular;
  }
  @mixin newDemandInfo-value-Font {
    font-size: 12px;
    color: #404040;
    line-height: 18px;
    font-family: Regular;
  }

  .newDemandInfo-item {
    // margin-bottom: 10px;
    .label {
      @include newDemandInfo-label-Font;
      margin-right: 12px;
    }
    .value {
      @include newDemandInfo-value-Font;
    }
    .name {
      display: inline-block;
      font-size: 14px;
      color: #101010;
      line-height: 22px;
      font-family: Regular;
      font-weight: 500;
      margin-bottom: 6px;
    }
    // &:last-child {
    //   margin-bottom: 0px;

    // }
    // &:first-child {
    //   margin-bottom: 10px;
    // }
  }
}

.info {
  width: 756px;
  box-sizing: border-box;
  padding: 24px 30px 24px 0px;
  .info-title {
    margin-bottom: 12px;
    img {
      width: 8px;
      height: 10px;
      margin-right: 12px;
    }
    .label {
      font-size: 16px;
      font-family: Medium;
      font-weight: 500;
      color: #101010;
      line-height: 24px;
    }
  }
  .info-item {
    display: inline-block;
    border-radius: 34.5px;
    padding: 10px 16px;
    border: 1px solid #f1f1f1;
    color: #101010;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background-color: #fafafa;
    font-family: 思源黑体 CN Regular;
  }
  .info-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #404040;
    font-family: 思源黑体 CN Regular;
  }

  .info-img {
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 68px;
      height: 72px;
    }
  }

  .info-consulting {
    .tit {
      background-color: #2885ff;
      padding: 6px;
      box-sizing: border-box;
      display: inline-block;
      border-radius: 2px 8px 8px 8px;
      color: #ffffff;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 20px;
    }
    .consulting-item {
      margin-bottom: 20px;
      .label {
        font-size: 12px;
        line-height: 18px;
        color: #8896a7;
        margin-right: 12px;
      }

      .value {
        font-size: 12px;
        line-height: 18px;
        color: #404040;
      }
    }
  }
}

@media screen and (max-width: 992px) {
}
</style>